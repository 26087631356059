/* controls the header element */
h1 {
	font-weight: normal;
	font-size: 40px;
	margin-left: 30px;
}
/* removes the bullet points for the unordered lists */
ul {
	padding-left: 0 !important;
	display: flex;
	list-style: none;
}
/* provides spacing and padding for the main navigation */
.topnav {
	margin: 0;
}
/* styles the cursor over the list
items of the topnav of the Userpage */
.topnav li {
	cursor: pointer;
}
/* encapsulates the add buttons and the search box */

#root > main > nav {
	margin: 0 30px;
	display: flex;
	height: 80px;
	position: relative;
	background: white;
	border-radius: 10px 10px 0 0;
}
/* search box for the userlist (when selecting) */
#searchBox {
	width: 90%;
}
/* search box for the userlist (when selecting) */
#searchBoxUserList {
	width: 100%;
	margin: auto;
}
/* reset button for the userList dialog */
#resetUserListBtn {
	width: 10%;
}
/* reset button for all the table lists */
#resetTableListBtn { 
	height: 30px;
    width: 30px;
    cursor: pointer;
    margin: 5px;
	color: rgb(131, 146, 171);
}

/* div that contains the search box and the reset button */
#userListSearchandReset {
	display: flex;
}
/* Defines the size of the add buttons block */
.addItem {
  margin: 0;
}

.addItem li{
	width: auto;
	margin: 0 2px;
}

.addItem .row{
	justify-content: flex-start;
	align-items: flex-start;
}
/* defines the size of the search box */
.search {
	display: flex;
	margin: 0;
    max-width: 100%;

}

.search #search {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing: 0.00938em;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    -webkit-box-align: center;
    padding: 0.5rem 0.75rem;
    border: 0.0625rem solid rgb(210, 214, 218);
    border-radius: 0.5rem;
    pointer-events: auto;
    display: grid !important;
    place-items: center !important;
    width: 100% !important;
    height: auto !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.4 !important;
    color: rgb(73, 80, 87) !important;
    background-color: rgb(255, 255, 255) !important;
    background-clip: padding-box !important;
    appearance: none !important;
    transition: box-shadow 150ms ease 0s, border-color 150ms ease 0s, padding 150ms ease 0s !important;
}


/* moves all the column headers to the left */
table > thead > tr:nth-child(2) > th {
	text-align: left;
	color: #C9C9C9;
}

/* only removing the main table header */
table > thead > tr:nth-child(1) > th {
	display: none;
}
tr:nth-child(2) > th {
	text-align: left;
}
/* styles the ul in Userlist for (Users, Groups, Deleted) */

/* styling the Userlist page and Knowledge Base page navigation buttons */
.topLine, #taskButton, #deleteChecklist {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing: 0.02857em;
    /* min-width: 64px; */
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 700;
    border-radius: 0.5rem;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    transition: all 150ms ease-in 0s;
    box-shadow: rgb(0 0 0 / 11%) 0rem 0.25rem 0.4375rem -0.0625rem, rgb(0 0 0 / 7%) 0rem 0.125rem 0.25rem -0.0625rem;
    min-height: 2rem;
    padding: 0.5rem 2rem;
    font-size: 0.75rem;
    background-image: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 82, 255)) !important;
    background-position-y: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    color: rgb(255, 255, 255) !important;
    background-size: 150% !important;
    background-position-x: 25% !important;
}
.bottomLine:hover {
	background-color:#C4DAFF !important;
}

.btn--confirm{
	background-image: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 82, 255)) !important;
	color: rgb(255, 255, 255) !important;
	
    position: relative !important;
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: transparent !important;
    outline: 0px !important;
    margin: 0px 5px !important;
    cursor: pointer !important;
    vertical-align: middle;
    appearance: none !important;
    text-decoration: none !important;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    letter-spacing: 0.02857em !important;
    min-width: 64px !important;
    border: 1px solid rgb(23, 193, 232) !important;
    display: inline-flex !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    font-weight: 700 !important;
    border-radius: 0.5rem !important;
    line-height: 1.4 !important;
    text-align: center !important;
    text-transform: uppercase !important;
    user-select: none !important;
    transition: all 150ms ease-in 0s !important;
    min-height: 2.125rem !important;
    padding: 0.5rem 2rem !important;
    font-size: 0.75rem !important;
}
/* .topLine:hover {
	background-color:#C4DAFF !important;
} */
/* Styling for Active Switch */
.switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
  }

  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }

  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
  }

  .slider:before {
	position: absolute;
	content: "";
	height: 18px;
	width: 18px;
	right: 21px;
	bottom: 1px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
  }

  input:checked + .slider {
	background-color: #2152ff;
	/* #17c1e8; */
  }

input:focus + .slider {
	box-shadow: 0 0 1px #2152ff;
}
input:checked + .slider:before {
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
/* styling the active toggle switch in the Reponse Plans Table */
#activeSwitch input:checked + .slider {
	background-color: #2152ff;
}
#activeSwitch input:focus + .slider {
	box-shadow: 0 0 1px #2152ff;
}
/* styling the practice toggle switch in the Reponse Plans Table */
#practiceSwitch input:checked + .slider {
	background-color: #2152ff;
}
#practiceSwitch input:focus + .slider {
	box-shadow: 0 0 1px #2152ff;
}


/* Styles the box on the Task List page */
#taskCompletedCheckbox {
	width: 25px;
	height: 25px;
	border:1px solid #147bb5;
	border-radius: 5px;
	cursor: pointer;
}
/* Styles the text area for the task description */
#taskDescription {
	font-size: 20px;
	font-family: 'Open Sans', sans-serif;
}
.planNameDescription {
	margin-top: 0px;
	margin-left: 30px;
    width: fit-content;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #aaaaaa;
}
/* Progress Bar in Tasklist */
#planName-ProgressBar-Div {
	display: flex;
}
/* task list progress bar desciption */
#progressBar-description {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-left: 150px;
}
/* task list progress bar */
#progressBar {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
	margin-top: 75px;
}
#progressBar::-webkit-progress-value {
	background: #147bb5;
	border-radius: .25rem;
}
#progressBar::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
#progressBarPercentage {
	position: relative;
	color: black;
}
/* styles the breadcrumbs throughout the website */
#breadCrumbs {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-direction: column;
    width: auto;
    box-sizing: border-box;
    flex-shrink: 0;
    /* position: sticky; */
    z-index: 1100;
    left: auto;
    right: 0px;
    /* box-shadow: rgb(255 255 255 / 90%) 0rem 0rem 0.0625rem 0.0625rem inset, rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem; */
    /* backdrop-filter: saturate(200%) blur(1.875rem); */
    background-color: rgba(255, 255, 255, 0.8);
    color: rgb(52, 71, 103);
    top: 0.75rem;
    min-height: 3.6875rem;
    display: grid;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 1rem;
    padding: 0.5rem 20px;
    margin: 1rem;
	font-size: 1rem;
    line-height: 1.5;
	font-weight: 600;
}

/* styles the icons buttons like the exit popup icon */
.icon_button {
	cursor: pointer;
	filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%);
}
/* Styles the box on the Task List page */
#userStatusBox {
	width: 20px;
	height: 20px;
	/* border:3px solid #147bb5; */
	border-radius: 50%;
}

/* rc list progress bar */
#rcProgressBar1 {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
}
#rcProgressBar1::-webkit-progress-value {
	background: #078f45;
	border-radius: .25rem;
}
#rcProgressBar1::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
#rcProgressBar2 {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
	margin-top: 20px;
}
#rcProgressBar2::-webkit-progress-value {
	background: #ffc60a;
	border-radius: .25rem;
}
#rcProgressBar2::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
#rcProgressBar3 {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
	margin-top: 20px;
}
#rcProgressBar3::-webkit-progress-value {
	background: #cc3232;
	border-radius: .25rem;
}
#rcProgressBar3::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
/* Roll Call User List page style filter select */
#selectFilter {
	margin: 0;
    height: auto;
    width: 150px;
    text-align: left;
    appearance: auto;
    text-align-last: left;
    padding: 5px;
    border: 1px solid #979797;
    border-radius: 5px;
}
/* styles the react select input box makes the box fit */
#searchBox input {
	margin: auto !important;
}
/* the chat box that opens up */
#chatBox {
	border-radius: 10px;
	border: 3px solid #147bb5;
	height: 500px;
	width: 350px;
	position: fixed;
    bottom: 100px;
    margin-right: 100px;
}
/* user input for the new message */
#chatBoxInput {
	border-radius: 20px;
	border-width: 1px;
	position: absolute;
	bottom: 5px;
	left: 5px;
	padding: 3px 10px;
	background: rgba(0, 0, 0, .05);
	width: -webkit-fill-available;
	margin-right: 9px;
	margin-bottom: 0px;
	text-indent: 17px;
}
/* click on element to add image (plus sign) */
#chatBoxSelectImage {
	opacity: 0;
	width: 0;
    height: 0;
}
/* plus sign that is shown in chat box */
#chatBoxPlusSign {
	font-size: 35px;
    position: absolute;
	bottom: 24px;
	width: 20px;
	height: 20px;
	left: 8px;
	cursor: pointer;
	color: #147bb5;
	z-index: 10;
}
/* the box of all the previous messages */
#messagesBox {
	position: absolute;
	left: 8px;
	margin-right: 17px;
    width: -webkit-fill-available;
	border: 1px solid black;
	border-radius: 5px;
	height: 400px;
	overflow: scroll;
}
/* list of messages */
#messageList {
	display: flex;
	flex-direction: column;
	margin-top: 0px;
}
#messageList li {
	margin-bottom: 5px;
	padding: 3px 10px;
	border-radius: 20px;
	max-width: 250px;
	width: fit-content;
  	width: -moz-fit-content;
}
/* send chat button */
#sendChatBtn {
	visibility: hidden;
}
/* current person in the chat */
.currUserMessage {
	background-color: lightblue;
	margin-left: auto;
}
/* The other person in the chat */
.otherUserMessage {
	background: #E5E5EA;
}
/* name of the other user who sent the message */
.otherUserName {
	font-size: 15px;
	color: lightgray;
}
/* name of the user who sent the message */
.currUserName {
	font-size: 15px;
	color: lightgray;
	margin-left: auto;
}
/* the box of all the previous messages */
#convoBox {
	position: absolute;
	bottom: 16px;
	border: 1px solid black;
	border-radius: 5px;
	height: 450px;
	width: 349px;
	overflow: scroll;
}
/* list of messages */
#convoList {
	display: flex;
	flex-direction: column;
	margin-top: 0px;
}
/* each individual message */
#convoList li {
	text-align: center;
	padding: 10px 0;
	border: 1px solid black;
	cursor: pointer;
}
/* Title of a chat between 2 people */
#chatTitle {
	text-align: center;
	margin-bottom: 8px;
}

#filterArrow {
	position: relative;
	top: 3px;
	height: 17px;
	cursor: pointer;
}
/* styles the container that holds the table and the piechart */
#dashboardPage article {
	display: block;
}
#dashboardTableCont > div > table {
	background: white;
    border-radius: 10px;
}
/* styles the dashboard table */
#dashboardPage table {
	margin: 0 ;
    width: 100%;
	border: none;
    height: auto;
    overflow: unset;
}

/* styles the pie chart and the stats */
#pieChartBox {
	position: relative;
    width: fit-content !important;
    left: 0px;
}
#pieChartStat {
	margin-bottom: 10px;
	background: #22397a;
    font-size: 20px;
	width: fit-content;
	height: fit-content;
	padding: 5px 30px;
	border-radius: 5px;
	color: white;
	text-align: center;
	position: relative;
	left: 0px;
}
#can {
    border: 2px solid #C9C9C9;
	border-radius: 50%;
	height: 200px;
	width: 200px;
}
#admincan {
    border: 2px solid #C9C9C9;
	border-radius: 50%;
	height: 200px;
	width: 200px;
}
#pieTitle {
	font-size: 18px;
    color: #00bcd4;
    font-weight: 500;
    margin-top: 0 !important;
}

/* styles the pie chart and the stats */
#pieChartBoxSuperAdmin {
	flex-direction: column;
	margin-left: 30px;
}

/* unread messages */
.dot {
	height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

/* Root admin select client to filter userlist  */
#selectClientUserTable {
    width: 100%;
    height: 40px;
    padding: 0px;
    font-size: 0.75rem;
    font-weight: 400;
    background-color: transparent;
    background-clip: padding-box;
    border: 0.0625rem solid rgb(210, 214, 218);
    /* appearance: none; */
    border-radius: 0.5rem;
    transition: box-shadow 150ms ease 0s, border-color 150ms ease 0s;
    cursor: pointer;
    box-shadow: none;
}

.tooltip{
	width:100px;
	height:100px;
}

#dashboardPage article div {
	width: 100%;
    margin: 0 auto;
}

#pieChartBox p{
	display: table;
}

.formsType{
	padding: 1%;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
    border-radius: 5px;
}
.formTable{
	padding:1rem;
}

.accordion .accordion__button {
    color: #fff;
    background: #17c1e8;
    cursor: pointer;
    padding: 7px 12px;
    width: 100%;
    text-align: left;
    border: none;
    font-size: 14px;
}

.accordion .accordion__button:hover {
    background-color: #fff;
    color: #000;
}

.formName {
    font-size: 16px;
    font-weight: 500;
}

.pro-sidebar{
	margin: 1rem;
	width: 220px !important;
    min-width: 220px !important;
}

.pro-sidebar-header {
    /* padding: 24px 32px 8px; */
    text-align: center;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    border: none  !important;
}


.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
	padding:  8px 30px 8px 30px !important;
}

.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 15px;
    color: #fff;
}

.pro-sub-menu > .pro-inner-list-item > div > ul {
    padding-top: 15px;
    padding-bottom: 15px;
    display: block;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item{
	background-color: #e5e9ed !important;
	max-width: 300px !important;

}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before{
	color: #fff !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item > .pro-item-content{
	color: #fff;
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
	height: auto;
    background-color: #e5e9ed !important;
    padding-left: 0;
    border-radius: 4px;
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
    left: -20px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper {
    display: none;
    color: #fff !important; 
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
    content: '';
    display: inline-block;
    width: 4px;
    min-width: 4px;
    height: 4px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin-right: 15px;
    position: relative;
	color: #fff !important;
}
/*.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
    position: absolute;
    padding-top: 80%;
    transform: translateY(-50%);
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
	background: #ffffff !important;
}

.pro-sidebar > .pro-sidebar-inner {
    background: #ffffff !important;
    font-family: Open Sans,sans-serif;
}
.pro-sidebar .pro-menu {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
    position: absolute;
    left: 10px;
    right: 0px;
    transform: translateY(80%);
    margin-top: 25px;
 }
 
.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 20px !important;
}

.popper-inner ul{
    display:block;
} */
.pro-sidebar > .pro-sidebar-inner{
	background-color: transparent !important;
}

.accordion{
	display: block !important;
  	margin: 0 14px;
}

.featureTitle_sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.featureTitle{
	display: inline;
    margin-right: 20px;
	font-size: 16px;
}

/* $submenu-bg-color-collapsed: #ffffff !default; */
/* $sidebar-bg-color: #1d1d1d !default;
$sidebar-color: #adadad !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #2b2b2b !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: #2b2b2b !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default; */


/* //new css */

.pro-sidebar.collapsed{
	width: 6rem !important;
    min-width: 6rem !important;
}