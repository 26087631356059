


/* styling the login page */
.signIn {
    max-width: 500px;
    max-height: 500px;
    padding: 20px;
    text-align: center;
    border-style: solid;
    border-width: 14px;
    border-radius: 5px;
    border-color: rgb(53, 116, 174);
    background: white;
}
.logo {
    margin: 20px 0;
}

.lgnTitle {
    font-size: 16px;
    margin: auto;
    margin-bottom: 20px;
    font-weight: 500;
}
/* the div around the input */
.inputbox {
    text-align: center;
    padding: 0 0 5px;
}
/* the actual input tag */
.logInput {
    padding: 5px;
    width: 300px;
    margin-bottom: 5px;
    border: none;
    border-radius: 7px;
}
.ltag {
    font-size: 20px;
}
.signInBtn {
    width: 140px;
    margin: 0;
    padding: 7px 14px;
    color: #fff;
    background: #036aa4;
    border: 0;
    border-radius: 7px;
    font-size: 18px
}
