/* styles the arrow to the left of the path header */
i {
    border: solid #aaaaaa;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
}
.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
/* styles the text in the path header */
.pathHeader {
    margin-left: 30px;
    width: fit-content;
    cursor: pointer;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #aaaaaa;
}
/* styles the Delete Folder button */
.folderBtn {
    width: 140px;
    margin: 0;
    margin-left: 3px;
    padding: 1em 2em;
    color: #fff;
    border: 0;
    border-radius: 10px;
    font-size: 13px;
}
.deleteFolderBtn {
    background: #ff0000;
}
.editFolderBtn {
    background: #036aa4;
}