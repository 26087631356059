/* styling the reports page */

/* PRINT BUTTON */
.icon-button-print {
  margin: 0;
}
.printButton {
  margin-left: 10%;
}

/* TOTAL RESULT */
/* .totalresultdiv{
    margin-top: 10px;
    width: 100px;
    margin-right: 1em;
} */
.totalresultspan {
  font-size: 14px;
  display: flex;
}
.totalresultsp {
  background: #2152ff;
  text-align: center;
  padding: 8% 4%;
  margin: 0;
  margin-left: 6px;
  width: 60%;
  height: 40px;
  color: #fff;
}

/* DATE RANGE */
.dateRange {
  display: flex;
  /* max-width: 100%;
    margin-top: 25px;
    max-height: 41px */
}
/* .startDate{
    margin-right: 5%;
} */

.datepickerStyle {
  outline: 0;
  border: 1px solid lightgrey;
  font-size: 12px;
  max-width: 130px;
  height: 30px;
  padding: 19px;
  border-radius: 7px;
  margin-right: 5px;
}

/* LABEL */
.label {
  color: grey;
}

table > thead > tr:nth-child(2) > th {
  color: #036aa4;
}

.planNameDescription {
  color: #036aa4;
}

.go {
  margin: 0;
  border: 1px solid #2152ff;
  border-radius: 7px;
  padding: 0 14px;
  background: #2152ff;
  color: #fff;
  height: 40px;
  font-size: 16px;
}

.reports__table-wrapper {
  min-height: 40vh;
}
/* GO button */
/* @media screen and (min-width: 1550px){

}
@media screen and (min-width: 1268px) and (max-width: 1549px){
  .go{
        margin: 0px 0px 25px 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;
    }
    .icon-button-print {
    margin: 25px 0 0 0px;
   }
   #selectClientUserTable {
    font-size: 17px;
    width: 20%;
    margin-top: 20px;
    color: #808080;
    top: 0;
    bottom: 0;
    margin-left: 54%;
    position: absolute;
    left: 0;
    right: 0;
    height: 38px;
    padding-left: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
}
}
@media screen and (min-width: 1130px) and (max-width: 1267px){
  .go{
        margin: 0px 0px 17px 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;
    }
}
@media screen and (min-width: 873px) and (max-width: 1129px){
  .go{
        margin: 0px 0px 24px 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;
    }
}
@media screen and (min-width: 701px) and (max-width: 872px){
  .go{
        margin: 0px 0px 30px 10px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;
    }
}
@media screen and (max-width: 700px){
  .go{
        margin: 0px 0px 36px 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;
    }
} */
#reminderButton {
  color: white;
  padding: 10px 20px;
  /* background: linear-gradient(to right, #233169, #21418c) !important; */
  background-image: linear-gradient(
    310deg,
    rgb(33, 82, 255),
    rgb(33, 82, 255)
  ) !important;
  border: 0;
  border-radius: 10px;
  font-size: 13px;
  cursor: pointer;
}

.bigDialogBox {
  height: fit-content;
}
