#userTable .col-head-name {
  width: 120px !important;
  display: block !important;
}
#userTable th {
  max-width: 240px !important;
}

.pagination {
  display: flex !important;
  align-items: center !important;
  gap: 0.75rem !important  ;
  padding: 0.5rem !important;
}

.pagination__btns {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pagination__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.5px;
  border: 1px solid rgb(229, 231, 235);
  border-radius: 0.25rem;
  background-color: var(--color-primary);
  color: var(--color-white);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.pagination__ctrl {
  display: flex;
  align-items: center;
  gap: 3.5px;
  font-size: 14px;
  font-weight: 400;
}

.pagination__input {
  width: 4rem !important;
  color: rgb(52, 71, 103) !important;
  padding-left: 1rem;
  border: 1px solid rgb(229, 231, 235) !important;
  border-radius: 0.25rem !important;
}

.pagination__select {
  max-width: 6rem !important;
  color: rgb(52, 71, 103) !important;
  font-size: 14px;
  font-weight: 400px;
  border: 1px solid rgb(229, 231, 235) !important;
  border-radius: 0.25rem !important;
  padding: 2.85px;
}
