.library__tree-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 0;
}
.library__tree-item__content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: rgb(103, 116, 142);
  font-size: 0.875rem;
  transition: background-color 0.2s ease-in-out;
}
.library__tree-item__content-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 400px;
}
.library__tree-item__content-timestamp {
  width: 12rem;
}
.library__tree-item__icon-btn {
  padding: 0 1rem;
}

#folder-update-dialog #file-picker {
  padding-bottom: 0.5rem;
}
