/* using this file to style App.js */
/* @import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap'); */
/* styling the body */
html, body {
    margin: 0;
	padding: 0;
    font-family: "Roboto", Helvetica, Arial, sans-serif
}
/* makes the main menu and the Userpage horizontally aligned */
#root {
    display: flex;
}
/* makes the background of the user list white */
#root > main {
    /* border: solid 1px #f1f4f6; */
    display: block;
    width: 87%;
}
/* styles the section that holds the user list */
#root > main > section {
    border: solid 1px #dddddd;
}
/* styles the div that contains the table */
#root > main > div {
    display: block;
}
/* styles the table */
#root > main > div > table {
    background: white;
}

/* styles the main navigation item */
/* .mainItem {

} */
/* styles the selected item of the main navigation */
.selectedItem {
    /* background: #036aa4; */
    color: #22397a
}
/* selected items icon (main left side bar) */
.selectedItem > img {
    filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%) !important;
}

#logoutButton {
    position: fixed;
    bottom: 50px;
    color: #22397a;
}
#logoutButton > img {
    filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%) !important;
}
/* styles the icons in the main navigation */
.mainItem > img {
    margin-bottom: -6px;
    filter: invert(88%) sepia(10%) saturate(518%) hue-rotate(189deg) brightness(88%) contrast(97%);
    /* filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%); */
}
/* styles the li tag that contains the backArrow */
#backArrow {
    position: absolute;
    top: 27px;
    left: 24px;
    cursor: pointer;
    z-index: 2;
}

#toggleMenuArrow {
    padding: 5px;
    position: fixed;
    top: 50px;
	border-radius: 50%;
    background: grey;
    z-index: 2;
    cursor: pointer;
    border: 3px solid white;
}
#signInBackGround {
    width: 100%;
    height: 100%;
    background: #fafeff; 
    /* // #147bb5; */
    position: fixed;
}
#signInBorder {
    /* border: solid 1px rgba(29, 29, 29, 0.25); */
    border-radius: 3px;
    box-shadow: 3px 5px 10px 0 rgba(0, 0, 0, 0.15);
    margin: 6rem auto;
    width: 445px;
    height: 470px;
}
#chatMessagesIcon {
    position: fixed;
    right: 50px;
    bottom: 50px;
    height: 60px;
    cursor: pointer;
}

#chatBackArrow {
    position: absolute;
    left: 8px;
    background: grey;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    height: 24px;
    padding: 2px;
}

#convoBox{
    height:426px !important;
    width:313px !important;
}
/* every even column has a light grey background */
/* tbody > tr:nth-child(even) {
    background: #f7f8f9;
} */

.hidden{
     display: none;
   }

/* .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul{
  display: block
} */

/* custom css */
.body{
    margin: 0px;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.625;
    letter-spacing: 0.00938em;
    background-color: rgb(248, 249, 250);
}

.toggleBtnCss{
    border: none;
    position: relative;
    top: 15px;
    left: 0;
    width: 30px;
    height: 30px;
    background: transparent;
    color: rgb(34 59 127);
    border-radius: 0;
    box-shadow: unset;
    cursor: pointer;
}

.toggleBtnCss svg {
    height: 30px;
    width: 30px;
}

.multi-divider {
    flex-shrink: 0;
    border-top: 0px solid rgba(0, 0, 0, 0.12);
    border-right: 0px solid rgba(0, 0, 0, 0.12);
    border-left: 0px solid rgba(0, 0, 0, 0.12);
    background-color: transparent;
    height: 0.0625rem;
    margin: 1rem 0px;
    border-bottom: none;
    opacity: 0.25;
    background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important;
}

.pro-icon-wrapper {
    opacity: 1;
    background: rgb(52, 71, 103);
    color:  rgb(255, 255, 255);
    align-items: center;
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
}
.pro-icon-wrapper svg {fill: #fff;}

.selectedItem .pro-icon-wrapper {
    /* color: rgba(0, 0, 0, 0.54); */
    flex-shrink: 0;
    background: rgb(33 114 255);
    /* //rgb(23, 193, 232); */
    min-width: 2rem;
    min-height: 2rem;
    border-radius: 0.5rem;
    display: grid;
    place-items: center;
    box-shadow: rgb(33 114 255 / 12%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(33 114 255 / 7%) 0rem 0.125rem 0.25rem -0.0625rem;
    transition: margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.selectedItem .pro-icon-wrapper .pro-icon{
    color: #fff;
}

.selectedItem .pro-icon-wrapper .pro-icon svg {
    fill: rgb(255, 255, 255);
    width: 20px;
}

.pro-icon svg {
    fill: #fff;
    /* rgb(33 116 254); */
    width: 20px;
}

.pro-sidebar{
    position: sticky !important;
    top: 0;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer{
    border: none !important;
}

.pro-item-content {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: block;
    font-weight: 400;
    font-size: 0.875rem;
    color: rgb(103, 116, 142);
}

.bTzKDq table th, .bTzKDq table td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 0.0625rem solid rgb(233, 236, 239);
    font-size: 12px;
    color: rgb(52, 71, 103);
}

.MuiBox-root {
    margin-top: 14px;
    margin-bottom: 24px;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
}

.MuiPaper-root {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0px;
    overflow-wrap: break-word;
    background-color: rgb(255, 255, 255);
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    box-shadow: rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
}

.css-1idik3a {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 24px;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
}

.css-uujcwg {
    line-height: 1;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    margin: 0;
}

.css-1yjo05o {
    display: flex;
    flex-direction: row;
    min-width: 23%;
}

.css-epib0t {
    margin: 0px;
    font-size: 1.25rem;
    line-height: 1.375;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing: 0em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(52, 71, 103);
    font-weight: 500;
}

.css-uujcwg h4 {
    margin: 0px;
    font-size: 1rem;
    line-height: 1.375;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing: 0em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(52, 71, 103);
    font-weight: 500;
}

.css-17vat79 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(103, 116, 142);
    font-weight: 400;
}

#secondaryNavigationItems {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    margin: 0px 5px;
    cursor: pointer;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing: 0.02857em;
    /* min-width: 64px; */
    border: 1px solid #2179fe;
    /* rgb(23, 193, 232); */
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 700;
    border-radius: 0.5rem;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    transition: all 150ms ease-in 0s;
    min-height: 2.125rem;
    padding: 0.5rem 2rem;
    font-size: 0.75rem;
    background-image: initial;
    background-position-y: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: transparent;
    color: #2179fe;
    /* rgb(23, 193, 232); */
    background-size: 150% !important;
    background-position-x: 25% !important;
}

.css-13ypi1f {
    width: 100%;
    overflow-x: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 1rem;
    box-shadow: none;
}

.css-fgimv3 {
    padding: 24px;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    margin: 0;
}

button.btn {
    width: auto !important;
    position: relative !important;
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: transparent !important;
    outline: 0px !important;
    margin: 0px 5px !important;
    cursor: pointer !important;
    vertical-align: middle;
    appearance: none !important;
    text-decoration: none !important;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    letter-spacing: 0.02857em !important;
    /* min-width: 64px !important;  rgb(23, 193, 232)  */
    border: 1px solid #2152ff !important;
    display: inline-flex !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    font-weight: 500 !important;
    border-radius: 0.5rem !important;
    line-height: 1.5 !important;
    text-align: center !important;
    text-transform: uppercase !important;
    user-select: none !important;
    transition: all 150ms ease-in 0s !important;
    min-height: 2.125rem !important;
    /* padding: 0.5rem 2rem !important; */
    font-size: 0.75rem !important;
    background-image: initial !important;
    background-position-y: initial !important;
    background-repeat: initial !important;
    background-attachment: initial !important;
    background-origin: initial !important;
    background-clip: initial !important;
    background-color: #2152ff !important;
    color: #fff !important;
    background-size: 150% !important;
    background-position-x: 25% !important;
}

.icon_button {
    font-size: 20px;
    filter: none !important;
    fill:#2152ff; 
    /* #17c1e8; */
}

.MuiBox-root.css-17uua2w {
    position: relative;
    margin: 14px;
}

.MuiBox-root.css-17uua2w table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;
}

.MuiBox-root.css-17uua2w table thead {
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
}

.MuiBox-root.css-17uua2w table thead tr {
    color: inherit;
    display: table-row;
    vertical-align: middle;
    outline: 0px;
}

.MuiBox-root.css-17uua2w table thead tr th {
    border-bottom: 0.0625rem solid rgb(233, 236, 239);
    padding: 12px 0;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    font-size: 12px;
}

.MuiBox-root.css-17uua2w table thead tr th span {
    position: relative;
    text-align: left;
    opacity: 0.7;
    background: transparent;
    color: rgb(131, 146, 171);
    font-size: 0.65rem;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
}

.MuiBox-root.css-17uua2w table tbody {
    display: table-row-group;
}

.MuiBox-root.css-17uua2w table tbody tr {
    color: inherit;
    display: table-row;
    vertical-align: middle;
    outline: 0px;
}

.MuiBox-root.css-17uua2w tbody tr td {
    text-align: left;
    font-size: 0.875rem;
    border-bottom: 0.0625rem solid rgb(233, 236, 239);
    padding: 7px 0;
    width: max-content;
    opacity: 1;
    background: transparent;
    color: rgb(103, 116, 142);
    vertical-align: middle;
}

.btn--close {
    background: transparent !important;
	color: rgb(0, 0, 0) !important;
	
    position: relative !important;
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: transparent !important;
    outline: 0px !important;
    margin: 0px 5px !important;
    cursor: pointer !important;
    vertical-align: middle;
    appearance: none !important;
    text-decoration: none !important;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    letter-spacing: 0.02857em !important;
    min-width: 64px !important;
    border: 1px solid #000 !important;
    display: inline-flex !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    font-weight: 700 !important;
    border-radius: 0.5rem !important;
    line-height: 1.4 !important;
    text-align: center !important;
    text-transform: uppercase !important;
    user-select: none !important;
    transition: all 150ms ease-in 0s !important;
    min-height: 2.125rem !important;
    padding: 0.5rem 2rem !important;
    font-size: 0.75rem !important;
}

ul#editSelectedUsersList li span {
    font-size: 14px;
}

ul#editSelectedUsersList li {
    border: 1px solid #9d9d9d;
    padding: 2px 7px;
}

.pro-sidebar-header .pro-icon-wrapper:first-child {
    background: none;
    display:unset
}

.pro-sidebar-header .pro-inner-item {
    display: unset;
}

.pro-sidebar-header span.pro-icon {
    display: unset !important;
    margin-bottom: 7px;
}