/* Button */
.btn {
  width: 140px;
  margin: 0 10px 0 0;
  padding: 1em 2em;
  color: #fff;
  background: linear-gradient(to right, #233169, #21418c) !important;
  border: 0;
  border-radius: 10px;
  font-size: 13px;
  cursor: pointer;
}
/* controls the buttons inside the Modal */
.btn--flex {
  display: flex;
  height: 35px;
  width: 290px;
  font-size: 20px;
}
/* styles the cancel button */
.btn--close {
  border-radius: 10px;
  background: rgb(53, 116, 174);
  cursor: pointer;
}
/* styles the confirm button*/
.btn--confirm {
  border-radius: 10px;
  background: rgb(33, 82, 255);
  cursor: pointer;
}

.dialog__btn {
  display: block;
  width: 100%;
  padding-bottom: 2em;
  padding-top: 0.7em;
}
h2 {
  font-weight: normal;
  font-size: 40px;
  text-align: left;
  margin-bottom: 20px;
}
label {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #1d1d1d;
}
/* inputs for Add User dialog */
input {
  outline: 0;
  border-width: 0 0 1px;
  border-color: black;
  font-size: 20px;
  width: 545px;
  margin-bottom: 25px;
}
/* changes the color of the placeholder for the inputs */
::placeholder {
  color: #c9c9c9;
}
/* controls the input for the admin level options */
select {
  font-size: 15px;
  margin-bottom: 10px;
  width: 545px;
}
/* controls the input for the profile picture */
.inputProfile {
  text-align: center;
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  border: solid 0.5px #cccccc;
  margin-top: 5px;
  margin-bottom: 17px;
}
.inputProfile input {
  opacity: 0;
  width: 0;
  height: 0;
}
.inputProfile {
  position: relative;
}
.inputProfile img {
  height: 75px;
  width: 75px;
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  margin: auto;
}
/* In addfileDialog.js -> create new Subfolder */
/* styles the button that creates a new subfolder */
#CreateNewSub {
  padding: 1em 2em;
  border-radius: 10px;
  background: rgb(53, 116, 174);
  color: white;
  margin-bottom: 10px;
  font-size: 13px;
}
/* styles the select below the create new subfolder */
#selectSubfolder {
  margin-bottom: 20px;
}

/* styles the add button on the addPlanDialog.js */
.addItemPlan {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  line-height: 1.25;
  letter-spacing: 0.03333em;
  opacity: 1;
  text-transform: capitalize;
  text-decoration: none;
  color: rgb(52, 71, 103);
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 7px;
  padding: 6px 7px;
  cursor: pointer;
}

.addItemText {
  font-size: 14px;
  padding-left: 4px;
}
/********** styles the selected users in create Plan page */
#selectedUsersList {
  display: block;
  width: 350px;
  margin: 20px 0;
}
/* styles the pencil icon to the right of the user name */
.selectedUserPencilIcon {
  position: absolute;
  padding-top: 8px;
  left: 350px;
  cursor: pointer;
  filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg)
    brightness(98%) contrast(92%) !important;
}
/* styles the minus sign to the right of the title/role */
.selectedUserMinusSign {
  position: absolute;
  right: 20px;
  font-size: 20px;
  color: red;
  font-weight: bolder;
  border-radius: 3px;
  line-height: 24px;
  padding: 0 5px;
  height: 20px;
  cursor: pointer;
}
/* for the task list, moved the edit icon sign to the right so
 * it does not overlap with the minus sign */
.taskPencil {
  left: 377px;
}
.taskMinusSign {
  margin-top: 5px;
}
/* adding some margin between all the users */
#selectedUsersList li {
  margin-bottom: 20px;
}
/*************** TITLE list that is displayed in the ERP page */
#titleList {
  display: block;
  width: 350px;
  margin: 20px 0;
}
/* added some margin below the task item */
#titleList > li {
  margin-bottom: 20px;
}

/*************** new task list that is displayed in the ERP page */
#newTasksList {
  display: block;
  width: 350px;
  margin: 20px 0;
}
/* added some margin below the task item */
#newTasksList > li {
  margin-bottom: 20px;
}
/* On edit user title in ERP, styles the UL that stores the roles */
#userRoleList {
  display: block;
  width: 350px;
  height: 150px;
}
/* styles the list item which is a checkbox/username */
#userRoleList li {
  display: flex;
}
/* styles the checkbox */
#userRoleList li div {
  margin: 0 10px 10px 0;
}
/* edit the users title for the ERP */
#editTitle {
  width: 325px;
}
/* controls the size of the dropdown for user role */
#selectUserTitle {
  width: 325px;
}
/*************** Edit Plan Box UserList *************/
#editSelectedUsersList {
  display: block;
  width: 350px;
  margin: 20px 0;
}

/* adding some margin between all the users */
#editSelectedUsersList li {
  margin-bottom: 20px;
}
/* new task list that is displayed in the ERP page */
#editTasksList {
  display: block;
  width: 350px;
  margin: 20px 0;
}
/* added some margin below the task item */
#editTasksList > li {
  margin-bottom: 20px;
}
/* title list that is displayed in the ERP page */
#editTitleList {
  display: block;
  width: 350px;
  margin: 20px 0;
}
/* added some margin below the title item */
#editTitleList > li {
  margin-bottom: 20px;
}
/********************** EDIT ERP Page ********/
/* role list this is displayed in the edit ERP page */
#editUserRoleList {
  display: block;
  width: 350px;
  height: 150px;
}
/* styles the list item which is a checkbox/username */
#editUserRoleList li {
  display: flex;
}
/* styles the checkbox */
#editUserRoleList li div {
  margin: 0 10px 10px 0;
}
/* styles the select title box */
#editSelectUserTitleBox {
  max-width: 100%;
}
/**************************** Add User Group  **************/
#editUserGroupList {
  display: block;
}
#editUserGroupList li {
  margin: 10px 0;
  border: 1px solid #ddd;
  padding: 2px 7px;
  font-size: 16px;
}
/* Dialog box that extends for most of the page and replaces the table */
.bigDialogBox {
  position: relative;
  top: 20px;
  left: 25px;
  right: 25px;
  height: auto;
  width: fit-content !important;
  padding: 1rem;
  background: #fff;
  border: 0;
  text-align: left;
  border-radius: 25px;
  overflow-y: auto;
  margin: 0 auto;
  width: 1024px !important;
  /* padding-right: 0rem !important; */
}
.bigDialogBox::backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.bigDialogBox h2 {
  margin: 0px;
  font-size: 1.25rem;
  line-height: 1.375;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
  font-weight: 500;
}

.bigDialogBox h4,
.bigDialogBox p {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(103, 116, 142);
  font-weight: 400;
}
.bigDialogBox::-webkit-scrollbar {
  width: 1em;
}

.bigDialogBox .row {
  padding: 1rem 0.75rem;
}

.bigDialogBox label {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  line-height: 1.25;
  letter-spacing: 0.03333em;
  opacity: 1;
  text-transform: capitalize;
  text-decoration: none;
  color: rgb(52, 71, 103);
  font-weight: 700;
  display: block;
}
.bigDialogBox input::placeholder {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  line-height: 1.25;
  letter-spacing: 0.03333em;
  opacity: 1;
  text-transform: capitalize;
  text-decoration: none;
  color: rgb(196, 196, 196);
  font-weight: 700;
}

.bigDialogBox input,
.bigDialogBox select,
.bigDialogBox textarea {
  width: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.00938em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  -webkit-box-align: center;
  padding: 0.5rem 0.75rem;
  border: 0.0625rem solid rgb(210, 214, 218);
  border-radius: 0.5rem;
  pointer-events: auto;
  display: grid !important;
  place-items: center !important;
  width: 100% !important;
  height: auto !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  color: rgb(73, 80, 87) !important;
  background-color: rgb(255, 255, 255) !important;
  background-clip: padding-box !important;
  transition: box-shadow 150ms ease 0s, border-color 150ms ease 0s,
    padding 150ms ease 0s !important;
}

#bulkUserImg {
  width: 65%;
  margin: 2rem 0;
}
/* style for the format image example format for add users in bulk*/
#bulkUserImg {
  border: solid 1px #cccccc;
  max-width: 97%;
}
/* on edit plan, edit the title of a user */
#editUserTitleBox {
  position: relative;
  top: 200px;
  height: auto;
  max-height: 55%;
  width: 20%;
  padding-top: 20px;
  padding-left: 20px;
  margin-left: 40%;
  background: #fff;
  border: 0;
  text-align: center;
}
/* on add new plan, edit the title of a user */
#editTitleBoxForPlan {
  position: relative;
  top: 200px;
  height: auto;
  max-height: 55%;
  width: 20%;
  padding-top: 20px;
  padding-left: 20px;
  margin-left: 40%;
  background: #fff;
  border: 0;
  text-align: center;
}
/************************** on new plan, displays the userList box *********/
/* style the Add New User Dialog for user Selection in ERP */
#UserBoxDialog {
  position: relative;
  top: 30px;
  width: 30%;
  padding: 1rem;
  background: #fff;
  border: 0;
  border-radius: 15px;
  text-align: center;
  overflow: unset;
}
/* title of the add users to ERP */
#userListBoxTitle {
  font-size: 16px;
}

#searchBoxUserList {
  font-size: 14px;
}
/****************** Abstracted the select users from planTable *************/
#userListOptions {
  display: block;
  width: 350px;
  margin-bottom: 20px;
}
/* styles the list item which is a checkbox/username */
#userListOptions li {
  display: flex;
}
/* styles the checkbox */
#userListOptions li input {
  width: 20px;
  margin-bottom: 10px;
}
/* exit from popup */
#exit-dialog-icon {
  position: absolute;
  right: 20px;
  cursor: pointer;
  z-index: 1;
}
/* user list in the Roll Call Dialog */
#editRollCallUserList {
  display: block;
  margin: 0;
}
#editRollCallUserList li {
  margin-bottom: 20px;
}

.disabled-input {
  font-style: italic !important;
}
